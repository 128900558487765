const designSkills = [
    "Sketch App",
    "Wireframing",
    "Prototyping",
    "Style Guides",
    "Researching",
    "Branding",
    "User Personas",
    "Balsamique",
    "Craft",
    "InVision",
    "Flinto",
    "Iconography"
]

export default designSkills;