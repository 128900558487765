const developerSkills = [
    "HTML",
    "CSS",
    "JavaScript",
    "React",
    "React Router",
    "TypeScript",
    "Frameworks",
    "DOM",
    "Databases",
    "Node.js",
    "Next.js",
    "APIs"
]

export default developerSkills;